import React from "react";
import styled from "styled-components";

import ex1 from '../../assets/osrs/example1.png';
import ex2 from '../../assets/osrs/example2.png';
import workflow from '../../assets/osrs/workflow.png';

const ProjectItemStyles = styled.div`
text-align: center;

li{
  list-style: none;
}

img{
  width: 90%;
  height: auto;
  padding-left: 15px;
  margin-bottom: 50px;
  margin-top: 20px;
}

.fluid-container{
  max-width: 1600px;
}

h1{
  padding-bottom: 50px;
}

p{
  max-width: 900px;
  margin-left: 22%;
}
@media only screen and (max-width: 1500px) and (min-width: 951px){
  p{
    max-width: 700px;
    margin-left: 20%;
  }
  h1{
    margin-left: -2%;
  }
}

@media only screen and (max-width: 1100px) and (min-width: 700px){
  p{
    max-width: 700px;
    margin-left: 5%;
  }
  h1{
    font-size: 62px;
    margin-left: -3%;
  }
}

@media only screen and (max-width: 699px){
  p{
    margin-left: 0%;
    max-width: 600px;
  }
}
`

function Osrsbot() {

  return (
    <ProjectItemStyles>
      <div className="fluid-container">
        <h1>An Ethical Hobbyist Bot</h1>
          <br/>
          <p>
            A hobby project in which I built a simple bot for a game using python and image processing libraries.
            The basic game involves training skills by repeating tasks in a manner that a computer can adequately handle,
            and uses a point and click system of movement on a tile grid. The image below shows the player bank.
            Additionally, the inventory is a simple grid of 28(bottom right corner).
            <br/><br/>
            <img src={ex1} alt="Example from the game" />
            <br/><br/>
            There is a player economy which is often impacted by for-profit botting, so I created a restricted account type
            which cannot trade with other players, as I was not interested in breaking the terms of service by trading botted resources.
            I created a simple gameplay loop which functioned by taking images captured at specific screen regions,
            grayscaling them, and performing a SSIM based on photos of the desired step's state of that screen region.
          </p>
          <br/><br/>
          <p>
            The final product's main loop followed this simple workflow:
            <img src={workflow} alt="Check Inventory - Chop Tree - Check Tree Stump - Bank if Full Inventory" />
            <br/><br/>
            You would start at the correct tile, direction, and camera angle and tell the program how many trips of filling the inventory with logs
            you wanted it to embark on, and it could be left fully unsupervised to do just that. The nature of this solution to tracking game data is
            a double-edged sword; it's easier to implement than the common methods, but in order to add a new route or add mining ore one needs
            to go through the full route tracking exact screen region bounding coordinates for different elements and screengrabbing to have index
            images for the program to compare against.
            <br/><br/>
            <img src={ex2} alt="Example from the game" />
            <br/><br/>
          </p>
          <ul>
            <li><b>1:</b> Area of screengrab for inventory check. Could be subdivided to determine specific slots</li>
            <li><b>2:</b> Button to deposit all items from inventory</li>
            <li><b>3:</b> Close bank</li>
            <li><b>4:</b> Compass to check at init if in correct orientation, can be clicked to reset</li>
          </ul>
          <br/><br/>
          <p>
            By grabbing the regions of the item slots in the inventory one can easily incorporate any different items for inventory checks,
            and with a slight improvement full inventory tracking and stats/health could also be tracked. The major problems with using computer vision
            rather than bytecode analysis of the source code is that any of these steps can be much simpler by reading game data. I went with computer vision
            because at the time I was still learning python and this was a fun application, but in the future I would likely embark on the much harder journey of
            learning how the common methods of bytecode manipulation are implemented and build from there, as it would allow for implentation of a neural net
            or at the very least a more robust algorithm to handle in-game events.
          </p>
        </div>
    </ProjectItemStyles>
  );
}

export default Osrsbot;
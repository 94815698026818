
import React from "react";
import {useState} from 'react';
import { MdSearch } from 'react-icons/md';
import ProjectItem from './ProjectItem';
import ProjectInfo from '../assets/imgs/projects';
import { useEffect } from "react";

function Home() {
  
  const [projectData, setProjectData] = useState(ProjectInfo);

  const [searchText, setSearchText] = useState('');

  function handleChange(e){
    e.preventDefault();
    setSearchText(e.target.value);
    if(!e.target.value.length > 0){
      setProjectData(ProjectInfo);
    }
  }

  useEffect(()=>{
    if(searchText === '') return;
    setProjectData(()=>
      ProjectInfo.filter( item => (
        item.name.toLowerCase().match(searchText.toLowerCase()) 
      ))
    );
  },[searchText]);

  return (

    <div className="fluid-container">
    <h1 className="home-h1">Projects</h1>

    <div className="projects_searchBar">
      <form>
        <input type="text"
        value={searchText}
        onChange={handleChange}
        placeholder="Project Name"
        />
        <MdSearch className="searchIcon"/>
      </form>
    </div>
    <div className="projects_allItems">
      {projectData.map( item => (
        <ProjectItem key={item.id} title={item.name} desc={item.desc} img={item.img} link={item.link}/>
      ))}
    </div>
  </div>
  );
}

export default Home;
import React from "react";
import styled from "styled-components";

const ProjectItemStyles = styled.div`
  #details{
    margin-top: 5px;
    margin-right:10px;
    cursor: pointer;
  }
  #2012{
    display:none;
  }
  #2016{
    display:none;
  }
  html,body {
    height: 100%;
    width: 100%;
    margin: 0;
    overflow-x: hidden;
}

h1{
    text-align: center;

    left: -100px;
    top: -100px;
}

#map{
    z-index: 100 !important;
	margin-top: 5%;
	height: 95%;
    width: 100%;
}

#return-btn{
    position: absolute;
    z-index: 5000 !important;
    left: 950px;
    top: -25px;
}

.state {
    z-index: 2500 !important;
    pointer-events: all !important;
}

.features{
    z-index: 5000 !important;
}

.chart{
    margin-top: 0px;
    margin-left: 25px;
    height: 1300px;
    width: 1200px;
}

#blurb{
    left: 55%;
    top: 350px;
}
#title{
    left: 570px;
    top: 130px;
}

#details-tab{
    position: absolute;
    bottom: -850px;
    height: 800px;
}
#details{
    z-index: 110 !important;
    position: absolute;
    bottom: 0px;
    left: 50px;
}
#details-blurb{
    margin-left: 400px;
    margin-top: 250px;
}

.tooltip{
    padding-left: 5px;
    padding-right: 5px;
}

#d3MapLayerBox{
	display: none;
}


#tabs{
	z-index: 2000 !important;
	background: grey;
	color:white;
    padding: 5px;
    left: 50px;
}
  
.state {
    stroke: lightgray;
    stroke-width: 1.5px;
}

#buttondiv {
    position: absolute;
    left: 120px;
    top: 100px;
    background: black;
    z-index: 99;
  }

  #buttons{
        padding-right: 12rem !important;
  }

#legend {
    position: absolute;
    left: 60px;
    bottom: 20px;
    width: 400px;
    height: 95px;
    background: white;
    z-index: 99;
}
`

const myHTML =`<div className="container-fluid row" onload="generateCarto()">
<div id="map" className="container-fluid"></div>
<div id="legend"></div>
<h1 id="title" className="col-7 position-absolute"><b>What's the state of the election?</b><br/>Dynamic Election Cartograms</h1>
<div id="blurb" className="position-absolute">
    <h3>What is a cartogram?</h3>
    <p className="col-5">
        A cartogram is a thematic map in which geographic size is altered to be directly proportional to a variable,
        which in this map is the margin of votes in each state.
    </p>
    <br/>
    <h3>What data is being displayed?</h3>
    <p className="col-5">
        Each tab changes the cartogram to display normalized state vote margins for the chosen year's presidential race.
    </p>
    <p className="col-5">
        States are colored based on their respective leaning towards the winning party. Click more information below for more details!
    </p>
    <h3>What's dynamic here?</h3>
    <p className="col-5">
        In future elections this cartogram and bar chart will update as new data becomes available, providing an easy glimpse into current battlegrounds.
    </p>
</div>
<a href="#details" className="btn btn-block" id="details">\/ More Information \/</a>
<div id="tabs" className="col-3">
    <div id="2012">
        Main Candidates: Barack Obama and Mitt Romney<br/>
        Result: Obama Win
    </div>
    <div id="2016">
        Main Candidates: Hillary Clinton and Donald Trump<br/>
        Result: Trump Win
    </div>
    <div id="2020">
        Main Candidates: Joseph Biden and Donald Trump<br/>
        Result: Biden Win
    </div>
</div>
</div>

<div id="details-tab">
<p id="details-blurb" className="col-3 float-end">
This bar chart displays the raw vote margins from the 2020 election used to form the cartogram.
These margins were normalized and changed to percentages before input to the algorithm.
<br/><br/>
The cartogram algorithm used was an extension to d3 written by <a href="https://github.com/shawnbot/topogram" rel="noreferrer" target="_blank">Shawn Allen </a>
and made useable with topojson by <a href="https://gist.github.com/emeeks/d57083a45e60a64fe976" rel="noreferrer" target="_blank">Elijah Meeks</a>.
<br/><br/>
Created by <a href="/" target="_blank">Vincent Cunningham</a>.
</p>
<button id="return-btn" onclick="returnToTop()">Back to cartogram</button>
<div className="chart position-absolute">
</div>
</div>`;

function Cartogram() {

  return (
    <ProjectItemStyles>

{/*
    <div dangerouslySetInnerHTML={__html: myHTML};>
    </div>

    <script src="https://d3js.org/d3.v3.min.js" charset="utf-8" type="text/javascript"></script>
    <script src="https://d3js.org/colorbrewer.v1.min.js" charset="utf-8" type="text/javascript"></script>
    <script src="https://rawgit.com/emeeks/d3-carto-map/master/d3.carto.map.js" type="text/javascript"></script>
    <script src="https://d3js.org/topojson.v1.min.js" type="text/javascript"></script>
    <script src="/src/assets/carto/cartogram.js" type="text/javascript"></script>
    <script src="/src/assets/carto/legend.js" type="text/javascript"></script>
    <script type="text/javascript" src="/src/assets/carto/main.js"></script> 
*/}

    </ProjectItemStyles>
  );
}

export default Cartogram;
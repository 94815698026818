import React from "react";
import styled from "styled-components";

import p1 from '../../assets/ptolemy/ptolemy1.png';
import p2 from '../../assets/ptolemy/ptolemy2.png';

import doc from '../../assets/ptolemy/VWC-BM-Thesis.pdf'

const ProjectItemStyles = styled.div`
  text-align: center;

  img{
    width: 350px;
    height: 350px;
    padding-left: 15px;
    margin-bottom: 50px;
    margin-top: 20px;
  }
  #thesis{
    margin-top: 5px;
    margin-bottom:-5px;
  }

  p{
    margin-top: -10px;
    margin-left: 29%;
    max-width: 800px;
  }

  h2{
    margin-top: 20px;
  }
  h4{
    margin-top: 0px;
  }

  #download{
    margin-left: 2%;
    padding-bottom: 1%;
  }

  .container{
    margin-left: 0%;
  }

  @media only screen and (max-width: 1500px) and (min-width: 951px){
    p{
      max-width: 1000px;
      margin-left: 9%;
    }
    h1{
      margin-left: -10%;
    }
    #download{
      margin-left: 5%;
    }
  }
  
  @media only screen and (max-width: 1100px) and (min-width: 700px){
    p{
      max-width: 700px;
      margin-left: 12%;
    }
    h1{
      font-size: 62px;
      margin-left: -3%;
    }
    #download{
      margin-left: 3%;
    }
  }
  
  @media only screen and (max-width: 699px){
    p{
      margin-left: 0%;
      max-width: 600px;
    }
    #download{
      margin-bottom: 50px;
    }
  }
`

function Ptolemy() {

  return (
    <ProjectItemStyles>
        <div className="container-fluid">
        <h2>The Medieval Copies of Claudius Ptolemy's Maps:<br /> A Semiotic Analysis</h2>
		<div className="container-fluid">
			<div className="text-center">
				<img className="rounded" src={p1} alt="Ptolemy Semiotics 1"/>
				<img className="rounded" src={p2} alt="Ptolemy Semiotics 2"/>
			</div>
		<p >
		Cartography is an inherently iterative field in which the works of the past often influence
		the works of the present. This study investigates the ways in which Cladius Ptolemy’s maps
		influenced European map making upon their reemergence in the 14th and 15th centuries. By
		analyzing collected maps based on Peirce/’s semiotic frameworks and existing carto-semiotic
		literature we tracked and compared the use of signs in Ptolemaic maps and non-Ptolemaic
		mappamundi from the same time period. Our analysis shows the progression of symbolization
		and illustration in early Medieval Ptolemaics and begins to touch on the possible influences of
		Ptolemy in other types of maps at this time as this type of map grew to be more stylized. We
		conclude that Ptolemaics of the surveyed period showed a tendency towards mapping the secular
		geography of humanity rather than the common religious or cultural narratives of the period.
		<br/><br/>
		<h4>My undergraduate capstone thesis for GIS/Cartograhpy:</h4>
		<br/>
		<a id="thesis" href={doc}><i><b>The Medieval Copies of Claudius Ptolemy's Maps:<br/> A Semiotic Analysis</b></i></a>
		</p>

		<a id="download" href={doc} download="VC-BM-Capstone.pdf">
			Download as PDF
		</a>
      </div>
    </div>
    </ProjectItemStyles>
  );
}

export default Ptolemy;
import React from "react";
import styled from "styled-components";

import p1 from '../../assets/vr/tools.png';
import p2 from '../../assets/vr/originalDesign.png';
import p3 from '../../assets/vr/updatedDesign.png';

const ProjectItemStyles = styled.div`
text-align: center;

img{

  padding-left: 15px;
  margin-bottom: 50px;
  margin-top: 20px;
}

h1{
  margin-bottom: 30px;
}

.fluid-container{
  max-width: 1000px;
  margin-left: 23%;
}

iframe{
  height: 600px;
  width: 100%
}


@media only screen and (max-width: 1400px) and (min-width: 951px){
  .fluid-container{
    max-width: 700px;
    margin-left: 25%;
  }
}

@media only screen and (max-width: 950px) and (min-width: 700px){
  .fluid-container{
    max-width: 700px;
    margin-left: 10%;
  }
}

@media only screen and (max-width: 699px){
  .fluid-container{
    margin-left: 0%;
    max-width: 600px;
  }
}
`

function Vrgarden() {

  return (
    <ProjectItemStyles>
      <div className="fluid-container">
        <h1>A retrospective of my VR final project</h1>
        <br/>
        <p>
          For the final project of my Virtual Reality course I was left with full creative freedom.
          All we had to do was come up with a project that used VR in some way,
          and structure our progress in the right way.

          At the time I had been doing remote coursework for almost three semesters, and I was brought back to the topic of another student's
          research during my undergraduate thesis in Spring of 2020. They had been researching the upswing of gardening after the start of the global pandemic.
          Having lived in a dorm or apartment for the past five years, I have at several points wished for a garden without any real possibility of having one.
          Following this train of thought I arrived at my project pitch: A peaceful VR gardening experience.
        </p>
          <br/>
        <h2>Peaceful VR Garden</h2>
        <p>
          <img className="col-8 col-sm-10 mx-auto" src={p2} alt="Original Design Document" />
          <br/><br/>
          If my studies of Cartography and Computer Science here at UW-Madison have taught me anything, 
          it is that there is always a difference between your ideal design and the execution you achieve.
          My design goals have remained consistent in that over the course of this project I have been focused on the simple gardening loop,
          but my ideal features have changed as new considerations became a part of my process. Included below is my updated design document
          that showcases the full list of basic and ideal features which I have worked on.
          <br/><br/>
          <img className="col-8 col-sm-10 mx-auto" src={p3} alt="Updated Design Document" />
          <br/><br/>
          If you are interested in a more thorough retrospective or footage of the project please see the video I created for my final project submission
          embedded at the bottom of this page.
          Overall, this project and the course as a whole have provided more context for my understanding of modern graphics and 3D computing,
          and as a bonus the final project provided me with a fairly comprehensive knowledge of the 3D graphics pipeline and Unity.
          <br/><br/>
          This project features (mostly) handmade 3D models which I made in Blender, a low-poly skybox thanks to Boxophobic,
          and quite a few C# scripts all working in a Unity3D project and using the SteamVR Unity plugin.
          <br/><br/>
          <img className="col-8 col-sm-10 mx-auto" src={p1} alt="Tools" />
          <br/><br/>
          The user has three tools: a hoe, a seed packet, and a watering can. They must till the soil, plant a seed, and then water it to start the growth cycle.
          There is extended envrionment around the fenced in play area featuring trees and rocks by Boxophobic and handsculpted terrain which I added.
          I will hopefully one day revisit this project and implement several of my listed ideal features, but for now this stands as my version 1.0.
          <br/>
        </p>

        <iframe src="https://www.youtube.com/embed/ciCp100VhLQ" 
        title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
        allowFullscreen></iframe>
      </div>

    </ProjectItemStyles>
  );
}

export default Vrgarden;
import React from "react";
import styled from "styled-components";

import p1 from '../../assets/uwcl/2pm_status.png';
import p2 from '../../assets/uwcl/csv_capture.png';
import p3 from '../../assets/uwcl/330pm_status.png';
import p4 from '../../assets/uwcl/early_chart.png';
import p5 from '../../assets/uwcl/barChart.png';
import p6 from '../../assets/uwcl/props1.png';
import p7 from '../../assets/uwcl/ForeignFilms.png';

const ProjectItemStyles = styled.div`
text-align:center;

img{
  padding-top: 30px;
  padding-bottom: 30px;
}

/* Charts */
.img1{
  width: 75%;
  height: auto;
}

/* Maps */
.img2{
  width: 100%;
  height: auto;
}

h1{
  padding-bottom: 5rem;
  margin-left: -3%;
}

p{
  max-width: 900px;
  margin-left: 22%;
}
@media only screen and (max-width: 1500px) and (min-width: 951px){
  p{
    max-width: 700px;
    margin-left: 20%;
  }
  h1{
    margin-left: -2%;
  }
}

@media only screen and (max-width: 1100px) and (min-width: 700px){
  p{
    max-width: 700px;
    margin-left: 5%;
  }
  h1{
    font-size: 62px;
    margin-left: -3%;
  }
}

@media only screen and (max-width: 699px){
  p{
    margin-left: 0%;
    max-width: 600px;
  }
}
`

function Uwcl() {

  return (
    <ProjectItemStyles>
        <div className="fluid-container">
        <h1>UWCL Design Challenge</h1>

        <p>
				Every spring the UW-Madison Cart Lab holds a mapping workshop that challenges students to work in small teams to create a map based
				on a given topic, with the help of an invited guest speaker, and within an eight hour session.
				<br/><br/>
				The 2020 Design Challenge featured Lauren Tierney, a cartographer and graphics reporter for The Washington Post.
				Lauren brought a very interesting perspective to the challenge, creating a simulation of a newsroom environment that lasted for the entire day.
				After workshopping ideas as a full group and then splitting into smaller groups based on our interests in certain current events we all frantically got to work,
				taking periodic pictures and getting feedback at set points.
				<br/><br/>
				The topic that myself and a classmate chose to explore was based on the (at that point recent) drama around the film Parasite winning Best Picture.
				To start we worked to compile data on previous nominees for Best Picture and Best International Film and explored how a film could qualify to win either category.
				<br/><br/>
				Our first pictures show the early stages of our research: a start on a split symbol proportional map of nominees versus wins per country
				and a formatted csv which was hand compiled that included the every nominee, if the film won, the language used, submitting country, and the director.


        <img className="img1" src={p2} alt="First CSV" />
				<img className="img2" src={p1} alt="Initial map" />
        <br/>


				The second set of pictures, taken an hour after the previous images, show the quick evolution of our data.
				The csv was parsed into a stacked bar chart by country of nominees and wins, and the split proportional symbol map was beginning to take shape.

      <br/>
        <img className="img2" src={p3} alt="Casual bar chart" />
				<img className="img1" src={p4} alt="Messy prop symbol map" />

      <br/>
				The final product of our eight hour design challenge shown below is what we presented to our peers at the end of the day.
				After pulling our data into these three visualizations we tied them together with a color scheme that we thought reflected to the viewer the experience of the movies.
				Our map included some of the interesting facts we had discovered in our research and the third graphic was added to reflect our data on the directors of Best Picture winners.

      <br/>
        <img className="img1" src={p5} alt="Polished bar chart" />
				<img className="img1" src={p6} alt="Visualization of directors" />
				<img className="img2" src={p7} alt="Final split proportional symbol map" />

        </p>
        </div>
    </ProjectItemStyles>
  );
}

export default Uwcl;
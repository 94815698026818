import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import projectImg from "../assets/imgs/t.png";

const ProjectItemStyles = styled.div`
    width: 350px;
    border-radius: 12px;
    border: 3px solid var(--gray-2);
    margin:15px;
    display:inline-block;

        opacity: 1.0;
        transition: transform .2s;
      }
        :hover {
        opacity: 0.8;
        transform: scale(0.9);
      }
    
    .projectItem_img{
        width: 100%;
        height: 100%;
        overflow: hidden;
        margin-top: 15px;
        border-radius: 12px;
        display:inline-block;
        border: 3px solid var(--gray-2);
        img{
            height: 100%;
        }
    }
    .projectItem_info{
        margin-top: 0.05rem;
    }
    .projectItem_desc{
        font-size: 1.1rem;
        margin-top: 10px;
        overflow-wrap: break-word;
    }
`

export default function ProjectItem(
    {
        img = projectImg,
        title = 'Project Name',
        link =  '/projects',
        desc = 'Default',
    }
){


    return(
        <ProjectItemStyles>
           <Link to={link} className="projectItem_img">
               <img src={img} alt="This should be a temp" />
            </Link>
            <div className="projectItem_info">
                <Link to={link}>
                    <h3 className="projectItem_title">{title}</h3>
                </Link>
            </div>
            <p className="projectItem_desc">
                {desc}
            </p>
        </ProjectItemStyles>
    )
}
import React from "react";
import styled from "styled-components";

import lab2_1 from '../../assets/g573/lab2-1.png';
import lab2_2 from '../../assets/g573/lab2-2.png';
import lab6_1 from '../../assets/g573/lab6-1.png';
import lab6_2 from '../../assets/g573/lab6-2.png';
import lab7_1 from '../../assets/g573/lab7-1.png';
import lab7_2 from '../../assets/g573/lab7-2.png';
import lab8_1 from '../../assets/g573/lab8-1.png';
import lab8_2 from '../../assets/g573/lab8-2.png';
import lab8_3 from '../../assets/g573/lab8-3.png';
import lab9_1 from '../../assets/g573/lab9-1.png';
import lab9_2 from '../../assets/g573/lab9-2.png';
import lab9_3 from '../../assets/g573/lab9-3.png';
import lab10_1 from '../../assets/g573/lab10-1.png';
import lab10_2 from '../../assets/g573/lab10-2.png';
import lab10_3 from '../../assets/g573/lab10-3.png';
import lab10_4 from '../../assets/g573/lab10-4.png';
import lab10_5 from '../../assets/g573/lab10-5.png';
import lab10_6 from '../../assets/g573/lab10-6.png';
import final_1 from '../../assets/g573/final-1.png';
import final_2 from '../../assets/g573/final-2.png';


const ProjectItemStyles = styled.div`
  h1{
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .fluid-container{
    max-width: 1500px;
    margin-left: 10%;
  }



  @media only screen and (max-width: 1500px) and (min-width: 951px){
    p{
      max-width: 1000px;
      margin-left: 5%;
    }
    h1{
      margin-left: -10%;
    }
  }
  
  @media only screen and (max-width: 1100px) and (min-width: 700px){
    p{
      max-width: 700px;
      margin-left: 5%;
    }
    h1{
      font-size: 62px;
      margin-left: -3%;
    }
  }
  
  @media only screen and (max-width: 699px){
    p{
      margin-left: 0%;
      max-width: 600px;
    }
  }

  text-align:center;

`

function G573() {

  return (
    <ProjectItemStyles>
        <div className="fluid-container">
        <h1>Advanced Geocomputing and Geospatial Big Data Analytics</h1>
          <br/><br/>
          <p>
            An extension of Big Data analysis with a focus on modern solutions to problems related to
            geospatial data collection and storage. Used Python and assorted packages, machine learning and AI, flickr and twitter APIs, and AWS services
            to collect, analyze, and display data. Normally I would trim down the content discussed, but genuinely every lab in this course explored something interesting.
            <br/><br/>
            Lab 2: Basic CSV to Map
            <br/><br/>
            A foundation for everything that followed, this first lab simply introduced Geopandas and how to read and plot csv data.
            <img src={lab2_1} alt="Head of geojson file" />
            <img src={lab2_2} alt="Basic map of geojson" />

            <br/><br/>
            Lab 3: Flickr API
            <br/><br/>
            This lab utilized the flickr API and geodata to query a chosen location within a certain radius of miles. I chose Devil's Lake State Park, and queried all images within
            the given timeframe and saved their information to a csv. This lab is included here as the data collected was used in Labs 6 and 7.
            <br/><br/>
            Lab 4/5: Web Scraper to CSV
            <br/><br/>
            Another quick project with not much to show, but one that used very interesting methods which were used later on. We were given a website, redfin, which collects real estate listings
            and can be searched by zipcode. We were tasked with using requests and Beautiful soup to query a chosen zip code, scrape the information and images for each
            house listed in the zip code, and then compile all of our collected data to csv.
            <br/><br/>
            Lab 6/7: Flickr Geospatial Analysis
            <br/><br/>
            Using data collected from Lab 3, in Lab 6 we experimented with several kinds of geospatial anaylsis. Below on the left is a simple bounding box point-in-polygon plot
            of one region of the photos I collected geotagged at Devil's Lake State Park. The right image is a convex hull of a different region, overlapping slightly.
            <br/><br/>
            <img src={lab6_1} alt="Flickr data plotted with bounding box" />
            <img src={lab6_2} alt="Convex hull of flickr data points" />
            <br/><br/>
            In Lab 7 we focused on several methods of clustering, two of which I have included below. K-means is a commonly used unsupervised ML clustering technique simply based on distance,
            and DBSCAN(Density Based Spatial Clustering of Applications with Noise) is a density-based clustering algorithm which treats low-density as noise.
            <img src={lab7_1} alt="K-mean clustering of flickr data" />
            <img src={lab7_2} alt="DBSCAN clustering of flickr data" />

            <br/><br/>
            Lab 8: Big Data!
            <br/><br/>
            This was the first lab working with truly Big Data. The two gray scale images below are census data for Madison, WI analyzed in a linear fashion, 
            with the second being an equalization to attempt to provide more contrast across the entire image. The final image captured for this lab was a full scale image of Wisconsin
            census data, categorized by race.
            <br/><br/>
            <img src={lab8_1} alt="Linear plot of census data" />
            <img src={lab8_2} alt="Eq-Hist of census data" />
            <img src={lab8_3} alt="Color scale of census race data" />

            <br/><br/>
            Lab 9: Isochromes and Carto
            <br/><br/>
            This lab started to explore other options to big data visualizations, specifically focused on CartoDB. Below are a few images of visualized population data.
            <img src={lab9_1} alt="Proportional symbol map" />
            <img src={lab9_2} alt="Isochrome of Philadelphia" />
            <img src={lab9_3} alt="Isochrome of Chicago" />

            <br/><br/>
            Lab 10: Centrality Maps
            <br/><br/>
            Lab 10 was concerned with the concept of centrality from network analysis and graph theory. We queried network data for our hometowns and graphed their streets based
            on the three primary types of centrality metric: degree centrality, closeness centrality, and betweenness centrality.
            <br/><br/>
            <img src={lab10_1} alt="Degree/closeness centrality histograms" />
            <img src={lab10_2} alt="Betweenness centrality histogram" />
            <br/><br/>
            Degree centrality is the number of ties a node has.
            <img src={lab10_3} alt="Degree centrality of Baraboo,WI" />
            <br/><br/>
            Closeness centrality is the average length of the shortest path between a node and every other node of the network.
            <img src={lab10_4} alt="Closeness centrality of Baraboo,WI" />
            <br/><br/>
            An example of a shortest path calculation.
            <img src={lab10_5} alt="Shortest path on Baraboo,WI network" />
            <br/><br/>
            And finally, betweenness centrality is the measure of how often a node is the 'bridge' of a shortest path between two other nodes.
            <br/><br/>
            <img src={lab10_6} alt="Betweenness centrality of Baraboo,WI" />
            <br/><br/>

            <br/><br/>
            Lab 11: Twitter API, Geoqueries, and Sentiment Analysis
            <br/><br/>
            Similar to the Flickr labs, this lab made use of an API and geodata and then performed a kind of analysis on the results.
            Unfortunately this project is much less interesting than it was in previous years, as Twitter has moved away from geotagged tweets.
            Sometimes tweets are still geotagged, but it is much rarer and usually only used for photos. We queried based on a tag/keywords and then
            performed a sentiment analysis on tweets to essentially poll the public sentiment on a topic. As it was close to the 2020 presidential elections,
            I queried Biden related tweets and attempted to perform an analysis of the general sentiment.
            <br/><br/>
            Final Project: Music Venues and UW-Madison Campus
            <br/><br/>
            In this group project we wanted to explore the music venue options available to students by performing a variety of the methods we explored in the course.
            We analyzed the shortest paths of venues in Madison and then performed a point in polygon check to eliminate venues outside of our acceptable range. We then
            web scraped common sites that catalogue shows with venue and date/time data and filtered this based on the venues we had selected. We then checked the times and dates 
            of shows to see what times and days of the week specific venues tended to book. Generally venues close to campus scheduled in the evening on Tuesdays, Fridays, and Saturdays.
            <img src={final_1} alt="Shortest path to Majestic Theater" />
            <img src={final_2} alt="Example of collected web scraped venue data" />

            <br/><br/>
		      </p>
      </div>
    </ProjectItemStyles>
  );
}

export default G573;
import React from "react";
import styled from "styled-components";

const ProjectItemStyles = styled.div`
  .embed-responsive-item{
    height: 100%;
  }
`

function Energy() {

  return (
    <ProjectItemStyles>
      <div className="embed-responsive embed-responsive-16by9">
			  <iframe title="energy" className="embed-responsive-item" src="https://storymaps.arcgis.com/stories/ad56e55570bd4844b6288e90be5bbe4f" width="100%" allowfullscreen allow="geolocation"></iframe>
		  </div>
    </ProjectItemStyles>
  );
}

export default Energy;